export default abstract class FileConverterService {
  /**
   * @info    File converter service
   *
   * @update  Lukas Laskowski 10.07.2019
   */
  public error: boolean = false;

  public abstract async convertToFileType(): Promise<any>;

  public abstract getFile(): File;
}
