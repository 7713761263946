import FileContract from '@/core/support/files/contracts/FileContract';
import FileConverterService from '@/core/support/files/FileConverterService';

import axios, {AxiosStatic, AxiosRequestConfig} from 'axios';
import GlobalSettings from '@/shared/lib/settings/GlobalSettings';

export default class ExternalUrlFileConverter extends FileConverterService {
  /**
   * @info    External url file type converter
   *
   * @update  Lukas Laskowski 10.07.2019
   */

  /**
   * Data
   */
  public url!: string | undefined;

  private filename!: string;
  private file!: File;

  private axios: AxiosStatic = axios;

  /**
   * Set model data
   * @param data
   */
  constructor(data: FileContract | null = null) {
    super();

    if (data === null) {
      return;
    }

    this.filename = data.filename ? data.filename : '-';
    this.url = data.url;
  }

  /**
   * Convert data to File
   */
  public async convertToFileType() {
    const config: AxiosRequestConfig = {
      withCredentials: true,
      responseType: 'blob',
      // onDownloadProgress: () => {}
    };

    this.setHeader('withCredentials', true);
    this.setHeader('Authorization', 'Bearer ' + GlobalSettings.token);

    await this.axios
      .get(this.url!, config)
      .then((response) => {
        const blob: Blob = new Blob([response.data], { type: response.data.type });

        this.file = new File([blob], this.filename);
      })
      .catch((reason) => {
        this.error = true;
      });
  }

  /**
   * Get File
   */
  public getFile(): File {
    return this.file;
  }

  /**
   * Set axios header
   * @param key
   * @param value
   */
  private setHeader(key: string, value: any) {
    this.axios.defaults.headers.common[key] = value;
  }
}
